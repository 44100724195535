
import {
  defineComponent, PropType, ref,
} from 'vue';
import ScrollMenuItem from '@/components/ui/menu/ScrollMenu/ScrollMenuItem.vue';
import { SearchIcon, XIcon } from 'heroicons-vue3/outline';
import _ from 'lodash';
import { mapGetters } from 'vuex';

export interface ScrollMenuType {
  name: string,
  link: string,
}

export default defineComponent({
  name: 'ScrollMenu',
  emits: ['updateCatalog', 'search'],
  components: {
    ScrollMenuItem,
    SearchIcon,
    XIcon,
  },
  props: {
    items: {
      type: Array as PropType<ScrollMenuType[]>,
      default: () => ([]),
    },
    currentCatalogId: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSearch: false,
    };
  },
  computed: {
    ...mapGetters({
      storeQuery: 'product/list/query',
    }),
  },
  methods: {
    updateCatalogHandler(catalogId: string, next: () => void) {
      this.$emit('updateCatalog', catalogId, next);
    },
    openSearch() {
      this.isSearch = true;
    },
    closeSearch() {
      this.isSearch = false;
      this.query = null;

      this.$emit('search', null);
    },
  },
  mounted() {
    if (this.storeQuery) {
      this.isSearch = true;
      this.query = this.storeQuery;
    }
  },
  setup(props, { emit }) {
    const query = ref(null);

    const debounceHandler = () => {
      emit('search', query.value);
    };

    const inputUpdateHandler = _.debounce(debounceHandler, 700);

    return { query, inputUpdateHandler };
  },
});
