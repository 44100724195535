import { Module } from 'vuex';
import { StateType } from '@/store';
import { AxiosCallResponse } from '@/api/base';
import ProductService, { ProductItem, ProductResponseType } from '@/api/products';
import _ from 'lodash';

export interface ProductListStateType {
  items: ProductItem[],
  searchItems: ProductItem[],
  offset: number,
  offsetSearch: number,
  query: string | null,
}

const initialState = {
  items: [],
  searchItems: [],
  offset: 0,
  offsetSearch: 0,
  query: null,
};

const productListModule: Module<ProductListStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    items: (state): ProductItem[] => state.items,
    searchItems: (state): ProductItem[] => state.searchItems,
    offset: (state): number => state.offset,
    offsetSearch: (state): number => state.offsetSearch,
    query: (state): string | null => state.query,
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setSearchItems(state, items) {
      state.searchItems = items;
    },
    setOffset(state, offset) {
      state.offset = offset;
    },
    setOffsetSearch(state, offset) {
      state.offsetSearch = offset;
    },
    setQuery(state, query) {
      state.query = query;
    },
  },
  actions: {
    async loadItems({ rootGetters, commit, state }, currentCatalogId: string) {
      const api: ProductService = rootGetters.$api.product;

      const response: AxiosCallResponse<ProductResponseType> = await api.list(
        currentCatalogId,
        state.offset,
        rootGetters['tag/list/selectedItems'],
      );

      const resultData = response.data?.Items ?? initialState.items;

      commit('setItems', _.uniqBy(state.items.concat(resultData), 'GUID'));
      commit('setOffset', state.items.length);
    },
    async loadSearchItems({
      rootGetters, commit, state, dispatch,
    }, query: string) {
      if (state.query !== query) {
        dispatch('setOffsetSearch', 0);
      }

      state.query = query;

      const api: ProductService = rootGetters.$api.product;

      const response: AxiosCallResponse<ProductResponseType> = await api.search(
        query,
        state.offsetSearch,
        rootGetters['tag/list/selectedItems'],
      );

      const resultData = response.data?.Items ?? initialState.searchItems;

      commit('setSearchItems', _.uniqBy(state.searchItems.concat(resultData), 'GUID'));
      commit('setOffsetSearch', state.searchItems.length);
    },
    setOffset({ commit }, offset: number) {
      commit('setItems', []);
      commit('setOffset', offset);
    },
    setOffsetSearch({ commit }, offset: number) {
      commit('setSearchItems', []);
      commit('setOffsetSearch', offset);
    },
    setQuery({ commit }, query: string | null) {
      commit('setQuery', query);
    },
  },
};

export default productListModule;
