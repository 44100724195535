
import { defineComponent, PropType } from 'vue';
import TheLogo from '@/components/catalog/TheLogo.vue';
import ScrollMenu, { ScrollMenuType } from '@/components/ui/menu/ScrollMenu/ScrollMenu.vue';

export default defineComponent({
  name: 'TheHeader',
  emits: ['updateCatalog', 'openSidebarMenu', 'search'],
  components: {
    TheLogo,
    ScrollMenu,
  },
  props: {
    menuItems: {
      type: Array as PropType<ScrollMenuType[]>,
      default: () => ([]),
    },
    currentCatalogId: {
      type: String,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateCatalogHandler(catalogId: string, next: () => void) {
      this.$emit('updateCatalog', catalogId, next);
    },
    searchHandler(query: string) {
      this.$emit('search', query);
    },
  },
});
