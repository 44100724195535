
import { defineComponent } from 'vue';
import { EyeSlashIcon } from '@heroicons/vue/24/outline';

export default defineComponent({
  name: 'NotFound',
  components: {
    EyeSlashIcon,
  },
});
