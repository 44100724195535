
import { defineComponent, PropType } from 'vue';
import { TagType } from '@/api/tags';
import TagDefault from '@/components/catalog/tags-filter/TagDefault.vue';
import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  name: 'TagsFilter',
  components: { TagDefault },
  props: {
    tags: Array as PropType<TagType[]>,
  },
  computed: {
    ...mapGetters({
      selectedTags: 'tag/list/selectedItems',
    }),
  },
  methods: {
    ...mapActions({
      toggleTag: 'tag/list/setTag',
    }),
    toggleTagHandler(tag: TagType) {
      this.toggleTag(tag);
    },
  },
});
