import { defineComponent } from 'vue';
import TheFooter from '@/components/ui/footer/TheFooter.vue';
import EntryAnimation from '@/components/animation/entry/EntryAnimation.vue';

export default defineComponent({
  name: 'App',
  components: {
    EntryAnimation,
    TheFooter,
  },
  data() {
    return {
      showAnimation: false,
      isPromo: false,
    };
  },
  created() {
    this.showAnimation = window.location.pathname !== '/promotion/register-discount-card';
    this.isPromo = window.location.pathname === '/promotion/register-discount-card';

    setTimeout(() => {
      this.showAnimation = false;
    }, 4000);
  },
});
