
import { defineComponent, PropType } from 'vue';
import ProductObject from '@/components/catalog/ProductCard.vue';
import { ProductItem } from '@/api/products';

export default defineComponent({
  name: 'ProductList',
  components: { ProductObject },
  props: {
    products: {
      type: Array as PropType<ProductItem[]>,
      required: true,
    },
  },
});
