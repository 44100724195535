<template>
  <div class="default-loader">
    <img src="@/assets/loader.png" alt="loader">
  </div>
</template>

<style scoped lang="scss">
.default-loader {
  @apply h-10 w-10;

  animation: spin 3.5s linear 0s normal none infinite running;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
<script setup lang="ts">
</script>
