import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00fc295e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-list" }
const _hoisted_2 = {
  key: 0,
  class: "absolute top-[50%] left-[50%] translate-x-[-50%]"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "font-bold text-xl mb-4 text-" }
const _hoisted_6 = { class: "flex flex-col space-y-[24px] mb-4" }
const _hoisted_7 = {
  id: "observer",
  class: "h-[1px]"
}
const _hoisted_8 = {
  key: 2,
  class: "load-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_default_loader = _resolveComponent("default-loader")!
  const _component_tags_filter = _resolveComponent("tags-filter")!
  const _component_product_object = _resolveComponent("product-object")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_button_loader = _resolveComponent("button-loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_default_loader)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_tags_filter, { tags: _ctx.tags }, null, 8, ["tags"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.prepareProducts, (item, group) => {
              return (_openBlock(), _createElementBlock("div", { key: group }, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(group), 1),
                _createElementVNode("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (item) => {
                    return (_openBlock(), _createBlock(_component_router_link, {
                      key: item.GUID,
                      to: `/product/${item.GUID}`
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_product_object, { data: item }, null, 8, ["data"])
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  }), 128))
                ])
              ]))
            }), 128))
          ])
        ])),
    _withDirectives(_createElementVNode("div", _hoisted_7, null, 512), [
      [_vShow, !_ctx.isLoadingPagination]
    ]),
    (_ctx.isLoadingPagination)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_button_loader, { class: "scale-50" })
        ]))
      : _createCommentVNode("", true)
  ]))
}