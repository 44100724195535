import { AxiosCallResponse, BaseService, ResponseOfRecords } from '@/api/base';

export interface TagType {
  GUID: string,
  Name: string,
}

export type TagsResponseType = ResponseOfRecords<TagType[]>;

export default class TagService extends BaseService {
  constructor(token: string) {
    super('gettegs', token);
  }

  list(): Promise<AxiosCallResponse<TagsResponseType>> {
    return this.axiosCall({ method: 'post', data: {} });
  }
}
