import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_header = _resolveComponent("the-header")!
  const _component_product_container = _resolveComponent("product-container")!
  const _component_product_search_list = _resolveComponent("product-search-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_the_header, {
      "menu-items": _ctx.prepareMenuItems,
      "current-catalog-id": _ctx.currentCatalogId,
      "is-loading": _ctx.isLoading,
      onUpdateCatalog: _ctx.updateCatalogHandler,
      onSearch: _ctx.searchHandler
    }, null, 8, ["menu-items", "current-catalog-id", "is-loading", "onUpdateCatalog", "onSearch"]),
    (!_ctx.query)
      ? (_openBlock(), _createBlock(_component_product_container, {
          key: 0,
          "current-catalog": _ctx.currentCatalog,
          onUpdateIsLoading: _ctx.updateIsLoadingHandler,
          onUpdateIsPaginationLoading: _ctx.updateIsLoadingPaginationHandler
        }, null, 8, ["current-catalog", "onUpdateIsLoading", "onUpdateIsPaginationLoading"]))
      : (_openBlock(), _createBlock(_component_product_search_list, { key: 1 }))
  ], 64))
}