import { Module } from 'vuex';
import { StateType } from '@/store';
import { AxiosCallResponse } from '@/api/base';
import TagService, { TagsResponseType, TagType } from '@/api/tags';

export interface TagListStateType {
  items: TagType[],
  selectedItems: TagType[],
}

const initialState = {
  items: [],
  selectedItems: [],
};

const tagListModule: Module<TagListStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    items: (state): TagType[] => state.items,
    selectedItems: (state): TagType[] => state.selectedItems,
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setSelectedItems(state, items) {
      state.items.sort((a) => (items.includes(a) ? -1 : 1));

      state.selectedItems = items;
    },
  },
  actions: {
    async loadItems({ rootGetters, commit }) {
      const api: TagService = rootGetters.$api.tag;

      const response: AxiosCallResponse<TagsResponseType> = await api.list();

      commit('setItems', response.data?.Items ?? initialState.items);
    },
    async setTag({ commit, state }, tag: TagType) {
      const tags = state.selectedItems;
      const selectedTag = tags.find((item: TagType) => item === tag);

      if (selectedTag) {
        commit('setSelectedItems', tags.filter((item: TagType) => item !== tag));
      } else {
        tags.push(tag);
        commit('setSelectedItems', tags);
      }
    },
  },
};

export default tagListModule;
