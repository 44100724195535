
export default {
  name: 'IconButton',
  data: () => ({
    isPress: false,
    isShowTooltip: false,
    timeoutId: null,
  }),
  props: {
    border: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    classList() {
      return `${(this.isPress ? 'pressed' : '') + (this.border !== '' ? 'bordered' : '')} ${this.border}`;
    },
  },
  methods: {
    showTooltip() {
      this.timeoutId = setTimeout(() => {
        this.isShowTooltip = true;
      }, 600);
    },
    hideTooltip() {
      this.isShowTooltip = false;
      clearTimeout(this.timeoutId);
    },
  },
};
