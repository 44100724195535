
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import ProductObject from '@/components/catalog/ProductCard.vue';
import DefaultLoader from '@/components/ui/loader/DefaultLoader.vue';
import ButtonLoader from '@/components/ui/loader/ButtonLoader.vue';
import _ from 'lodash';
import TagsFilter from '@/components/catalog/tags-filter/TagsFilter.vue';

export default defineComponent({
  name: 'ProductSearchList',
  emits: ['updateIsLoading'],
  components: {
    TagsFilter,
    ButtonLoader,
    DefaultLoader,
    ProductObject,
  },
  data() {
    return {
      isLoading: true,
      isLoadingPagination: false,
      isOpenProduct: false,
      lastOffset: 0,
      lastQuery: null,
    };
  },
  computed: {
    ...mapGetters({
      offset: 'product/list/offsetSearch',
      products: 'product/list/searchItems',
      query: 'product/list/query',
      tags: 'tag/list/items',
      selectedTags: 'tag/list/selectedItems',
    }),
    prepareProducts() {
      return _.groupBy(this.products, 'Group');
    },
  },
  methods: {
    ...mapActions({
      setCurrentProductId: 'catalog/list/setProductId',
      loadProducts: 'product/list/loadSearchItems',
      setOffset: 'product/list/setOffsetSearch',
      loadTags: 'tag/list/loadItems',
    }),
    async loadHandler() {
      if (this.products.length > 0
          && !this.isLoadingPagination
          && this.lastOffset !== this.offset
      ) {
        this.isLoadingPagination = true;

        this.lastOffset = this.offset;
        this.lastQuery = this.query;

        await this.loadProducts(this.query);

        if (this.query !== this.lastQuery) {
          this.lastOffset = 0;
        }

        this.isLoadingPagination = false;
      }
    },
  },
  watch: {
    'selectedTags.length': async function change() {
      this.isLoadingPagination = true;

      await this.setOffset(0);
      await this.loadProducts(this.query);

      this.isLoadingPagination = false;
    },
    products(value) {
      if (value.length > 0) {
        this.isLoading = false;
      } else {
        this.isLoading = true;
        this.lastOffset = 0;
      }
    },
    query() {
      window.scrollTo(0, 0);
    },
  },
  async mounted() {
    const observer = new IntersectionObserver(async () => {
      await this.loadHandler();
    });
    const observerMutation = new MutationObserver(async (element) => {
      const el = element[0].target as HTMLElement;
      if (el.style.display === 'none') {
        await this.loadHandler();
      }
    });

    const target = document.getElementById('observer') as HTMLElement;

    observer.observe(target);
    observerMutation.observe(target, {
      attributes: true,
    });

    if (this.$route.params.id) {
      await this.setCurrentProductId(this.$route.params.id);
      this.isOpenProduct = true;
    }
  },
  directives: {
    scroll: {
      mounted(el, binding) {
        window.addEventListener('scroll', binding.value);
      },
    },
  },
});
