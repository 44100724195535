
import { defineComponent, PropType } from 'vue';
import PhotoIcon from '@/components/ui/icon/PhotoIcon.vue';
import { mapGetters } from 'vuex';
import { ProductItem } from '@/api/products';
import { isArray } from 'lodash';
import ArrowUpIcon from '@/components/ui/icon/ArrowUpIcon.vue';
import LeftArrowIcon from '@/components/ui/icon/LeftArrowIcon.vue';
import RightArrowIcon from '@/components/ui/icon/RightArrowIcon.vue';

export default defineComponent({
  name: 'ProductObject',
  methods: { isArray },
  components: {
    RightArrowIcon,
    LeftArrowIcon,
    ArrowUpIcon,
    PhotoIcon,
  },
  props: {
    data: {
      type: Object as PropType<ProductItem>,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      storeCurrentProductId: 'catalog/list/currentProductId',
    }),
  },
});
