
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import DefaultLoader from '@/components/ui/loader/DefaultLoader.vue';
import ChevronLeftIcon from '@/components/ui/icon/ChevronLeftIcon.vue';
import IconButton from '@/components/ui/buttons/IconButton.vue';
import TheLogo from '@/components/catalog/TheLogo.vue';
import ScrollMenuItem from '@/components/ui/menu/ScrollMenu/ScrollMenuItem.vue';
import ImageSlider from '@/components/ui/images/ImageSlider.vue';
import ImageDefault from '@/components/ui/images/ImageDefault.vue';
import ProductObject from '@/components/catalog/ProductCard.vue';

export default defineComponent({
  name: 'TheProduct',
  components: {
    ProductObject,
    ImageDefault,
    ImageSlider,
    DefaultLoader,
    ChevronLeftIcon,
    IconButton,
    TheLogo,
    ScrollMenuItem,
  },
  data() {
    return {
      isLoading: false,
      id: this.$route.params.id,
      showFullDescription: false,
      showFullInstruction: false,
      currentActiveTab: '1',
    };
  },
  computed: {
    ...mapGetters({
      data: 'product/one/data',
    }),
    images() {
      return this.data.Images;
    },
    shortDescription() {
      return this.showFullDescription ? this.data.Description : `${this.data.Description.slice(0, 300)}...`;
    },
    shortInstruction() {
      return this.showFullInstruction ? this.data.Instruction : `${this.data.Instruction.slice(0, 300)}...`;
    },
    descriptionButton() {
      return !this.showFullDescription ? 'Показать больше.' : 'Скрыть.';
    },
    instructionButton() {
      return !this.showFullInstruction ? 'Показать больше.' : 'Скрыть.';
    },
    tabList() {
      return this.data.Instruction ? [
        {
          id: '1',
          text: 'Описание',
        },
        {
          id: '2',
          text: 'Инструкция',
        },
        {
          id: '3',
          text: 'Цена',
        },
      ] : [
        {
          id: '1',
          text: 'Описание',
        },
        {
          id: '3',
          text: 'Цена',
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      loadData: 'product/one/loadData',
      setSearch: 'product/list/setQuery',
    }),
    showDescription() {
      this.showFullDescription = !this.showFullDescription;
    },
    showInstruction() {
      this.showFullInstruction = !this.showFullInstruction;
    },
    updateTabHandler(id: string) {
      this.currentActiveTab = id;
    },
    closeProduct() {
      this.$router.back();
    },
  },
  watch: {
    '$route.params.id': async function changeProductId() {
      this.isLoading = true;

      await this.loadData(this.$route.params.id);

      this.isLoading = false;
    },
  },
  async created() {
    this.isLoading = true;

    await this.loadData(this.$route.params.id);

    this.isLoading = false;
  },
});
