
import { defineComponent, PropType } from 'vue';
import ChevronLeftIcon from '@/components/ui/icon/ChevronLeftIcon.vue';
import { CatalogItem } from '@/api/catalog';

export default defineComponent({
  name: 'CatalogList',
  components: { ChevronLeftIcon },
  props: {
    currentCatalog: {
      type: Object as PropType<CatalogItem>,
    },
  },
});
