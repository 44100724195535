import { createStore, Store } from 'vuex';
import api, { ApiServices } from '@/api';
import catalog, { CatalogStateType } from '@/store/modules/catalog';
import product, { ProductStateType } from '@/store/modules/products';
import tag, { TagStateType } from '@/store/modules/tag';

export interface StateType {
  catalog: CatalogStateType,
  product: ProductStateType,
  tag: TagStateType,
}

const store: Store<StateType> = createStore({
  getters: {
    $api: (): ApiServices => api(''),
  },
  modules: {
    catalog,
    product,
    tag,
  },
});

export default store;
