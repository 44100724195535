import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13f73b71"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "catalog-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevron_left_icon = _resolveComponent("chevron-left-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentCatalog?.Items, (catalog) => {
    return (_openBlock(), _createBlock(_component_router_link, {
      key: catalog.GUID,
      to: `/${_ctx.currentCatalog?.GUID}/${catalog.GUID}`
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, _toDisplayString(catalog.Name), 1),
          _createElementVNode("div", null, [
            _createVNode(_component_chevron_left_icon, { class: "rotate-180" })
          ])
        ])
      ]),
      _: 2
    }, 1032, ["to"]))
  }), 128))
}