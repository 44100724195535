import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from 'vue-router';

const NotFoundView = () => import('../views/not-found/NotFound.vue');
const CatalogView = () => import('../views/catalog/TheCatalog.vue');
const ProductPage = () => import('../views/product/TheProduct.vue');
const CardRegister = () => import('../views/promotion/CardRegister.vue');

const routes: RouteRecordRaw[] = [
  {
    path: '/promotion/register-discount-card',
    component: CardRegister,
  },
  {
    path: '/:catalogId?/:subCatalogId?',
    component: CatalogView,
  },
  {
    path: '/product/:id',
    component: ProductPage,
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
