import CatalogService from '@/api/catalog';
import ProductService from '@/api/products';
import TagService from '@/api/tags';

export interface ApiServices {
  catalog: CatalogService,
  product: ProductService,
  tag: TagService,
}

export default (token: string): ApiServices => ({
  catalog: new CatalogService(token),
  product: new ProductService(token),
  tag: new TagService(token),
});
