import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_object = _resolveComponent("product-object")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (item) => {
    return (_openBlock(), _createBlock(_component_router_link, {
      key: item.GUID,
      to: `/product/${item.GUID}`
    }, {
      default: _withCtx(() => [
        _createVNode(_component_product_object, { data: item }, null, 8, ["data"])
      ]),
      _: 2
    }, 1032, ["to"]))
  }), 128))
}