import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-320a0c66"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "product-list",
  ref: "productList"
}
const _hoisted_2 = {
  key: 0,
  class: "catalog-container"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_5 = {
  key: 1,
  class: "relative"
}
const _hoisted_6 = {
  key: 0,
  class: "catalog-header"
}
const _hoisted_7 = { class: "text-[16px] pt-1" }
const _hoisted_8 = {
  key: 2,
  class: "catalog-container"
}
const _hoisted_9 = {
  id: "observer",
  class: "h-[1px]"
}
const _hoisted_10 = {
  key: 2,
  class: "load-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_catalog_list = _resolveComponent("catalog-list")!
  const _component_default_loader = _resolveComponent("default-loader")!
  const _component_chevron_left_icon = _resolveComponent("chevron-left-icon")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_tags_filter = _resolveComponent("tags-filter")!
  const _component_not_found = _resolveComponent("not-found")!
  const _component_product_list = _resolveComponent("product-list")!
  const _component_button_loader = _resolveComponent("button-loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showCatalogList)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_catalog_list, { "current-catalog": _ctx.currentCatalog }, null, 8, ["current-catalog"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_default_loader)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_ctx.isOpenCatalog)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_icon_button, {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back())),
                        class: "mx-1"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_chevron_left_icon, { class: "w-6 h-6" })
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.currentCatalogName), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(_component_tags_filter, {
                  tags: _ctx.tags,
                  class: _normalizeClass({'mt-[54px]': _ctx.isOpenCatalog})
                }, null, 8, ["tags", "class"]),
                (_ctx.products.length <= 0)
                  ? (_openBlock(), _createBlock(_component_not_found, { key: 1 }))
                  : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_product_list, { products: _ctx.products }, null, 8, ["products"])
                    ]))
              ]))
        ])),
    _withDirectives(_createElementVNode("div", _hoisted_9, null, 512), [
      [_vShow, _ctx.showObserver]
    ]),
    (_ctx.isLoadingPagination && !_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(_component_button_loader, { class: "scale-50" })
        ]))
      : _createCommentVNode("", true)
  ], 512))
}