import { AxiosCallResponse, BaseService, ResponseOfRecords } from '@/api/base';
import { TagType } from '@/api/tags';

export interface ProductItem {
  Name: string,
  GUID: string,
  Images: string[],
  Price: string,
  Title: string,
  Volume: string,
  Items?: ProductItem[],
}

interface PriceType {
  PriceType: string,
  Price: number,
}
export interface ProductInfoItem {
  Name: string,
  GUID: string,
  Description: string,
  Instruction: string,
  Amount: number,
  FreeDelivery: boolean,
  Images: string[],
  Prices: PriceType[],
  Diameter?: string,
  Height?: string,
  RelatedProducts: ProductItem[],
}

export type ProductResponseType = ResponseOfRecords<ProductItem[]>;

export default class ProductService extends BaseService {
  constructor(token: string) {
    super('', token);
  }

  list(
    catalogId: string,
    offset: number,
    tags: TagType[],
  ): Promise<AxiosCallResponse<ProductResponseType>> {
    return this.axiosCall({
      method: 'post',
      url: 'getproducts',
      data: {
        CatalogGUID: catalogId,
        Offset: offset,
        Tegs: tags,
      },
    });
  }

  one(productId: string): Promise<AxiosCallResponse<ProductInfoItem>> {
    return this.axiosCall({
      method: 'post',
      url: 'getproductinfo',
      data: {
        GUID: productId,
      },
    });
  }

  search(
    query: string | null,
    offset: number,
    tags: TagType[],
  ): Promise<AxiosCallResponse<ProductResponseType>> {
    return this.axiosCall({
      method: 'post',
      url: 'search',
      data: {
        String: query,
        Offset: offset,
        Tegs: tags,
      },
    });
  }
}
