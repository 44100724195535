import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20e0e9e7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tooltip"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["icon-btn relative", $options.classList]),
    onMousedown: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isPress = true)),
    onMouseup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isPress = false)),
    onMouseenter: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.showTooltip && $options.showTooltip(...args))),
    onMouseleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($options.hideTooltip && $options.hideTooltip(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.isShowTooltip && $props.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("span", null, _toDisplayString($props.title), 1)
        ]))
      : _createCommentVNode("", true)
  ], 34))
}