import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5fa9026e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-block" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_photo_icon = _resolveComponent("photo-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.src)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.src,
          alt: _ctx.alt
        }, null, 8, _hoisted_2))
      : (_ctx.base64)
        ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: `data:image/png;base64, ${_ctx.base64}`,
            alt: _ctx.alt
          }, null, 8, _hoisted_3))
        : (_openBlock(), _createBlock(_component_photo_icon, {
            key: 2,
            class: "w-16 h-16"
          }))
  ]))
}