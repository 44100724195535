
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TagDefault',
  emit: ['toggleTag'],
  props: {
    id: String,
    name: String,
    active: Boolean,
  },
});
