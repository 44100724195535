
import { defineComponent } from 'vue';
import { PhotoIcon } from '@heroicons/vue/24/outline';

export default defineComponent({
  name: 'ImageDefault',
  components: {
    PhotoIcon,
  },
  props: {
    src: String,
    base64: String,
    alt: {
      type: String,
      required: true,
    },
  },
});
