import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c196eb4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tags-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tag_default = _resolveComponent("tag-default")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
      return (_openBlock(), _createBlock(_component_tag_default, {
        key: tag.GUID,
        id: tag.GUID,
        name: tag.Name,
        active: _ctx.selectedTags.includes(tag),
        onClick: ($event: any) => (_ctx.toggleTagHandler(tag))
      }, null, 8, ["id", "name", "active", "onClick"]))
    }), 128))
  ]))
}