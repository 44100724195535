import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0379377c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "relative",
  ref: "currentScrollItem"
}
const _hoisted_2 = ["data-catalog-id"]
const _hoisted_3 = {
  key: 0,
  class: "min-h-[4px] min-w-[4px] rounded-full bg-[#169586] absolute left-[50%] top-[110%]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass(["text-[#94A3B8] whitespace-nowrap cursor-pointer", { active: _ctx.isActive }]),
      "data-catalog-id": this.id,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectCatalog && _ctx.selectCatalog(...args)))
    }, _toDisplayString(_ctx.text), 11, _hoisted_2),
    (_ctx.isActive)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3))
      : _createCommentVNode("", true)
  ], 512))
}