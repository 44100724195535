import { Module } from 'vuex';
import { StateType } from '@/store';
import CatalogService, { CatalogItem, CatalogResponseType } from '@/api/catalog';
import { AxiosCallResponse } from '@/api/base';

export interface CatalogListStateType {
  items: CatalogItem[],
  currentCatalogId: string | null,
}

const initialState = {
  items: [],
  currentCatalogId: null,
};

const roleListModule: Module<CatalogListStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    items: (state): CatalogItem[] => state.items,
    currentCatalogId: (state): string | null => state.currentCatalogId,
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setCurrentCatalogId(state, id) {
      state.currentCatalogId = id;
    },
  },
  actions: {
    async loadItems({ rootGetters, commit }) {
      const api: CatalogService = rootGetters.$api.catalog;

      const response: AxiosCallResponse<CatalogResponseType> = await api.list();

      commit('setItems', response.data?.Items ?? initialState.items);
    },
    setCatalogId({ commit }, id: string) {
      commit('setCurrentCatalogId', id);
    },
  },
};

export default roleListModule;
