
import { defineComponent, PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { CatalogItem } from '@/api/catalog';
import ChevronLeftIcon from '@/components/ui/icon/ChevronLeftIcon.vue';
import DefaultLoader from '@/components/ui/loader/DefaultLoader.vue';
import ButtonLoader from '@/components/ui/loader/ButtonLoader.vue';
import IconButton from '@/components/ui/buttons/IconButton.vue';
import TagsFilter from '@/components/catalog/tags-filter/TagsFilter.vue';
import CatalogList from '@/components/catalog/product-container/catalog-list/CatalogList.vue';
import ProductList from '@/components/catalog/product-container/product-list/ProductList.vue';
import NotFound from '@/components/catalog/product-container/not-found/NotFound.vue';

export default defineComponent({
  name: 'ProductContainer',
  emits: ['updateIsLoading'],
  components: {
    NotFound,
    ProductList,
    CatalogList,
    TagsFilter,
    IconButton,
    ButtonLoader,
    DefaultLoader,
    ChevronLeftIcon,
  },
  data() {
    return {
      isLoading: false,
      isLoadingPagination: false,
      isOpenCatalog: false,
      localCurrentCatalogId: null,
      lastOffset: 0,
    };
  },
  props: {
    currentCatalog: {
      type: Object as PropType<CatalogItem>,
    },
  },
  computed: {
    ...mapGetters({
      offset: 'product/list/offset',
      products: 'product/list/items',
      tags: 'tag/list/items',
      selectedTags: 'tag/list/selectedItems',
    }),
    currentCatalogName() {
      return this.currentCatalog.Items?.find(
        (item: CatalogItem) => item.GUID === this.localCurrentCatalogId,
      )?.Name;
    },
    showCatalogList() {
      return this.currentCatalog?.Items?.length > 0 && !this.isOpenCatalog;
    },
    showObserver() {
      return !this.showCatalogList && this.products.length > 0 && !this.isLoadingPagination;
    },
  },
  methods: {
    ...mapActions({
      setCurrentProductId: 'catalog/list/setProductId',
      loadProducts: 'product/list/loadItems',
      loadTags: 'tag/list/loadItems',
      setOffset: 'product/list/setOffset',
    }),
    async loadHandler() {
      if (this.localCurrentCatalogId
        && this.products.length > 0
        && !this.isLoadingPagination
        && this.lastOffset !== this.offset
      ) {
        if (this.currentCatalog.GUID !== this.localCurrentCatalogId) {
          if (!this.isOpenCatalog) {
            return;
          }
        }

        this.isLoadingPagination = true;
        this.lastOffset = this.offset;

        await this.loadProducts(this.localCurrentCatalogId);

        this.isLoadingPagination = false;
      }
    },
  },
  watch: {
    'selectedTags.length': async function change() {
      this.isLoadingPagination = true;

      await this.setOffset(0);
      this.lastOffset = 0;
      await this.loadProducts(this.localCurrentCatalogId);

      this.isLoadingPagination = false;
    },
    async currentCatalog(catalog: CatalogItem, prevCatalog: CatalogItem) {
      if (catalog.GUID === prevCatalog.GUID) {
        return;
      }

      this.isLoading = true;
      this.isLoadingPagination = true;
      this.$emit('updateIsLoading', this.isLoading);
      this.isOpenCatalog = false;
      this.lastOffset = 0;
      this.localCurrentCatalogId = catalog.GUID;

      if (catalog.Items?.length <= 0) {
        await this.loadProducts(catalog.GUID);
      }

      this.isLoading = false;
      this.isLoadingPagination = false;
      this.$emit('updateIsLoading', this.isLoading);
    },
    '$route.params': async function changeSubCatalog(value) {
      if (!value.subCatalogId) {
        this.isOpenCatalog = false;
        await this.setOffset(0);
      } else {
        this.isLoading = true;
        this.isLoadingPagination = true;
        this.$emit('updateIsLoading', this.isLoading);

        this.isOpenCatalog = true;
        this.localCurrentCatalogId = value.subCatalogId;
        await this.loadProducts(value.subCatalogId);

        this.isLoading = false;
        this.isLoadingPagination = false;
        this.$emit('updateIsLoading', this.isLoading);
      }
    },
  },
  async mounted() {
    if (this.$route.params.subCatalogId) {
      this.isOpenCatalog = true;
      this.localCurrentCatalogId = this.$route.params.subCatalogId;
    }

    if (!this.isOpenCatalog && this.$route.params.catalogId) {
      this.localCurrentCatalogId = this.$route.params.catalogId;
    }

    // OBSERVER DATA LOADER
    const target = document.getElementById('observer') as HTMLElement;

    const observer = new IntersectionObserver(async () => {
      await this.loadHandler();
    });

    observer.observe(target);

    // const observerMutation = new MutationObserver(async (element) => {
    //   const el = element[0].target as HTMLElement;
    //   if (el.style.display === 'none') {
    //     await this.loadHandler();
    //   }
    // });
    //
    // observerMutation.observe(target, {
    //   attributes: true,
    // });

    if (localStorage.getItem('offset')) {
      this.$refs.productList.scrollTop = localStorage.getItem('offset');
    }

    this.$refs.productList.onscroll = () => {
      localStorage.setItem('offset', this.$refs.productList.scrollTop);
    };

    await this.loadTags();
  },
  directives: {
    scroll: {
      mounted(el, binding) {
        window.addEventListener('scroll', binding.value);
      },
    },
  },
});
