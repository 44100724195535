
import { defineComponent } from 'vue';
import { ChevronDownIcon } from '@heroicons/vue/24/outline';
import axios, { AxiosError } from 'axios';
import TheLogo from '@/components/catalog/TheLogo.vue';
import router from '@/router';

export default defineComponent({
  name: 'CardRegister',
  components: {
    TheLogo,
    ChevronDownIcon,
  },
  data() {
    return {
      firstName: null,
      name: null,
      middleName: null,
      date: null,
      phone: null,
      source: null,
      newsLetter: true,
      personalInfo: false,
      isSuccess: false,
      isConflict: false,
      isError: false,
      isLoading: false,
    };
  },
  methods: {
    async submitForm(event: Event) {
      if (this.firstName && this.name && this.date && this.phone && this.source) {
        event.preventDefault();

        try {
          this.isLoading = true;

          await axios.request({
            method: 'post',
            url: 'https://prokeratin36.ru/NeofloraPlants/hs/Site/app/newcard',
            data: {
              FIO: [this.firstName, this.name, this.middleName].join(' '),
              datebirth: this.date,
              newsletter: this.newsLetter,
              source: this.source,
              phone: this.phone.replace(/[^\d+]/g, ''),
              isHomePlants: true,
            },
          });

          this.isSuccess = true;
          this.isLoading = false;
          setTimeout(() => router.push('/'), 2000);
        } catch (e: unknown) {
          const error = e as AxiosError;

          if (error?.response?.status === 409) {
            this.isConflict = true;
          } else {
            this.isError = true;
          }
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
  mounted() {
    const title = document.querySelector('title') as HTMLElement;
    title.innerText = 'Зарегистрируйте дисконтную карту NeoFlora';
  },
});
