import { Module } from 'vuex';
import { StateType } from '@/store';
import list, { TagListStateType } from '@/store/modules/tags/list';

export interface TagStateType {
  list: TagListStateType,
}

const tagsModule: Module<TagStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
  },
};

export default tagsModule;
