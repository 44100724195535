
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import TheHeader from '@/components/catalog/TheHeader.vue';
import { CatalogItem } from '@/api/catalog';
import ProductSearchList from '@/components/catalog/ProductSearchList.vue';
import ProductContainer from '@/components/catalog/product-container/ProductContainer.vue';

export default defineComponent({
  name: 'TheCatalog',
  components: {
    ProductSearchList,
    TheHeader,
    ProductContainer,
  },
  data() {
    return {
      isLoading: false,
      isPaginationLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      catalog: 'catalog/list/items',
      searchProducts: 'catalog/list/items',
      query: 'product/list/query',
      currentCatalogId: 'catalog/list/currentCatalogId',
    }),
    prepareMenuItems() {
      return this.catalog.map((item: CatalogItem) => ({
        name: item.Name,
        link: item.GUID,
      }));
    },
    currentCatalog() {
      return this.catalog.find((item: CatalogItem) => item.GUID === this.currentCatalogId) ?? {};
    },
  },
  methods: {
    ...mapActions({
      loadCatalog: 'catalog/list/loadItems',
      loadSearchItems: 'product/list/loadSearchItems',
      setCurrentCatalogId: 'catalog/list/setCatalogId',
      setOffset: 'product/list/setOffset',
    }),
    updateCatalogHandler(catalogId: string, next: () => void) {
      this.setCurrentCatalogId(catalogId);
      this.setOffset(0);

      window.scrollTo({ top: 0 });
      setTimeout(() => {
        next();
      }, 0);
    },
    updateIsLoadingHandler(value: boolean) {
      this.isLoading = value;
    },
    updateIsLoadingPaginationHandler(value: boolean) {
      this.isPaginationLoading = value;
    },
    async searchHandler(query: string) {
      await this.loadSearchItems(query);
    },
  },
  watch: {
    '$route.params.catalogId': async function changeCatalogId(value) {
      if (value) {
        await this.setCurrentCatalogId(value);
      } else {
        await this.setCurrentCatalogId(this.catalog[0].GUID);
      }
    },
  },
  async mounted() {
    this.isLoading = true;

    await this.loadCatalog();

    if (this.currentCatalogId === null) {
      if (!this.$route.params.catalogId) {
        await this.setCurrentCatalogId(this.catalog[0].GUID);
      } else {
        await this.setCurrentCatalogId(this.$route.params.catalogId);
      }
    }

    window.scrollTo({ top: 0 });

    this.isLoading = false;
  },
});
