import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_the_footer = _resolveComponent("the-footer")!
  const _component_entry_animation = _resolveComponent("entry-animation")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view),
      _createVNode(_component_the_footer)
    ], 512), [
      [_vShow, !_ctx.showAnimation]
    ]),
    (_ctx.showAnimation)
      ? (_openBlock(), _createBlock(_component_entry_animation, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}