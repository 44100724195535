import './assets/tailwind.css';
import { createApp, Directive } from 'vue';
import App from '@/App.vue';
import i18n from '@/i18n';
import router from '@/router';
import store from '@/store';
// @ts-ignore
import VueYandexMetrika from 'vue3-yandex-metrika';
// @ts-ignore
import { mask } from 'vue-the-mask';

const app = createApp(App)
  .use(store)
  .use(i18n)
  .use(router)
  .use(VueYandexMetrika, {
    id: 94660690,
    router,
    env: process.env.VUE_APP_NODE_ENV,
  });

app.directive('mask', mask as Directive);

app.mount('#app');
